<template>
  <div>
    <b-modal
      id="historicoCompraList"
      ref="historicoCompraList"
      button-size="sm"
      no-close-on-backdrop
      no-stacking
      ok-olny
      ok-title="Ok"
      size="lg"
      cancel-title=""
      :cancel-disabled="true"
      cancel-variant="false"
    >
      <div slot="modal-title">
        <h5>Histórico de aquisição de créditos - {{ getEmpresaNome() }}</h5>
        <fieldset class="font-weight-bold text-muted">
          Histórico de aquisição de créditos da empresa
        </fieldset>
      </div>
      <visualizacao
        :historico="historico"
      />
    </b-modal>
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
  z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1051 !important;
}
</style>

<script>
import {
  getUserInfo,
} from '@/auth/utils'

export default {
  components: {
    Visualizacao: () => import('../visualizacao/Visualizacao.vue'),
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      historico: [],
    }
  },
  created() {
    this.getHistorico()
  },
  methods: {
    show() {
      this.getHistorico()
      this.$refs.historicoCompraList.show()
    },
    getHistorico() {
      this.api.getHistoricoCompra()
        .then(payload => {
          this.historico = payload.data
        })
    },
    getEmpresaNome() {
      return getUserInfo().empresa
    },
  },
}

</script>
